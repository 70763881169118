import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react"
import firebase from "gatsby-plugin-firebase"
import store from "store"

export const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  }

  function loginWithGoogle() {
    const googleProvider = new firebase.auth.GoogleAuthProvider()
    return firebase.auth().signInWithPopup(googleProvider)
  }

  function logout() {
    store.remove("user")
    store.remove("stoken")
    store.remove("sdate")
    return firebase.auth().signOut()
  }

  function resetPassword(email) {
    return firebase.auth().sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = useMemo(
    () => ({
      currentUser,
      login,
      signup,
      logout,
      resetPassword,
      updateEmail,
      updatePassword,
      loginWithGoogle,
    }),
    [currentUser]
  )

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
