import "./src/styles/global.css"
import React from "react"
import "firebase/auth"
import AuthProvider from "./src/components/context/auth"
import ReactQueryProvider from "./src/components/context/reactQuery"
import { ReactQueryDevtools } from "react-query/devtools"

const nodeEnv = !process.env.NODE_ENV || process.env.NODE_ENV === "development"

export const wrapRootElement = ({ element }) => (
  <ReactQueryProvider>
    <AuthProvider>{element}</AuthProvider>
    {nodeEnv && <ReactQueryDevtools />}
  </ReactQueryProvider>
)
