import React, { useEffect } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import axios from "axios"
import store from "store"

const queryClient = new QueryClient()

export async function GetToken() {
  const { data } = await axios.post(
    `${process.env.GATSBY_API_URL}/auth/local`,
    {
      identifier: process.env.GATSBY_USER_IDENTIFIER,
      password: process.env.GATSBY_USER_PASSWORD,
    }
  )
  store.set("stoken", data.jwt)
  store.set("sdate", Date.now())
}

const expireTime = 120 * 60 * 1000
const diff = Date.now() - store.get("sdate")
if (!diff || diff > expireTime) {
  GetToken()
}

setInterval(() => {
  GetToken()
}, expireTime)

export const ReactQueryProvider = props => {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  )
}

export default ReactQueryProvider
