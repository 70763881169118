exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-exam-details-js": () => import("./../../../src/components/templates/exam-details.js" /* webpackChunkName: "component---src-components-templates-exam-details-js" */),
  "component---src-components-templates-paper-details-js": () => import("./../../../src/components/templates/paper-details.js" /* webpackChunkName: "component---src-components-templates-paper-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-analysis-js": () => import("./../../../src/pages/app/analysis.js" /* webpackChunkName: "component---src-pages-app-analysis-js" */),
  "component---src-pages-app-articles-js": () => import("./../../../src/pages/app/articles.js" /* webpackChunkName: "component---src-pages-app-articles-js" */),
  "component---src-pages-app-enrolled-all-js": () => import("./../../../src/pages/app/enrolled-all.js" /* webpackChunkName: "component---src-pages-app-enrolled-all-js" */),
  "component---src-pages-app-examlist-js": () => import("./../../../src/pages/app/examlist.js" /* webpackChunkName: "component---src-pages-app-examlist-js" */),
  "component---src-pages-app-exams-js": () => import("./../../../src/pages/app/exams.js" /* webpackChunkName: "component---src-pages-app-exams-js" */),
  "component---src-pages-app-fulltests-js": () => import("./../../../src/pages/app/fulltests.js" /* webpackChunkName: "component---src-pages-app-fulltests-js" */),
  "component---src-pages-app-home-js": () => import("./../../../src/pages/app/home.js" /* webpackChunkName: "component---src-pages-app-home-js" */),
  "component---src-pages-app-notifications-js": () => import("./../../../src/pages/app/notifications.js" /* webpackChunkName: "component---src-pages-app-notifications-js" */),
  "component---src-pages-app-popular-all-js": () => import("./../../../src/pages/app/popular-all.js" /* webpackChunkName: "component---src-pages-app-popular-all-js" */),
  "component---src-pages-app-premium-js": () => import("./../../../src/pages/app/premium.js" /* webpackChunkName: "component---src-pages-app-premium-js" */),
  "component---src-pages-app-profile-js": () => import("./../../../src/pages/app/profile.js" /* webpackChunkName: "component---src-pages-app-profile-js" */),
  "component---src-pages-app-subjecttests-js": () => import("./../../../src/pages/app/subjecttests.js" /* webpackChunkName: "component---src-pages-app-subjecttests-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructions-js": () => import("./../../../src/pages/instructions.js" /* webpackChunkName: "component---src-pages-instructions-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-paperquiz-js": () => import("./../../../src/pages/paperquiz.js" /* webpackChunkName: "component---src-pages-paperquiz-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-old-js": () => import("./../../../src/pages/registerOld.js" /* webpackChunkName: "component---src-pages-register-old-js" */),
  "component---src-pages-result-js": () => import("./../../../src/pages/result.js" /* webpackChunkName: "component---src-pages-result-js" */),
  "component---src-pages-test-2-js": () => import("./../../../src/pages/test2.js" /* webpackChunkName: "component---src-pages-test-2-js" */),
  "component---src-pages-test-3-js": () => import("./../../../src/pages/test3.js" /* webpackChunkName: "component---src-pages-test-3-js" */),
  "component---src-pages-test-4-js": () => import("./../../../src/pages/test4.js" /* webpackChunkName: "component---src-pages-test-4-js" */),
  "component---src-pages-test-exam-1-js": () => import("./../../../src/pages/testExam1.js" /* webpackChunkName: "component---src-pages-test-exam-1-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-urql-js": () => import("./../../../src/pages/urql.js" /* webpackChunkName: "component---src-pages-urql-js" */)
}

