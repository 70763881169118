import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client"
import { fetch } from "isomorphic-fetch"
import store from "store"

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: `${process.env.GATSBY_API_URL}/graphql`,
    headers: {
      Authorization: `Bearer ${store.get("stoken")}`,
    },
    fetch,
  }),
  shouldBatch: true,
})

export default client
